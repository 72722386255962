* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* carousel styles override */
.carousel .slide img {
  width: auto !important;
}

.carousel .slide {
  text-align: initial;
}

.carousel .control-dots {
  max-width: 1300px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding-right: 50px;
  padding-left: 50px;
}

.carousel .control-dots .dot {
  width: 48px;
  height: 48px;
  margin: 0 16px 0 0;
  border-radius: unset;
  background: transparent;
  background: url("assets/icons/slider-arrow.svg");
  opacity: 1;
  box-shadow: none;
}

.carousel .control-dots .dot:nth-child(2) {
  transform: rotate(180deg);
}