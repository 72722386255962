*
    margin: 0
    padding: 0

$primaryColor: #091133  
$SolidBlack:#000
$backgroundColor:#E7ECFF
$btnColor: #1f1454        

.Tech-main-section
    margin:0% 0% 0% 0%
    padding-bottom: 0%
    text-align: center
    background-color: #f8f9ff
.logo
    position: relative // Position relative for absolute positioning of the pseudo-element
    transition: transform 0.3s, box-shadow 0.3s
    animation: twinkle 3s infinite // Transition for smooth effect
    &.logo1
        animation-delay: 0s // Start animation immediately
    &.logo2
        animation-delay: 0.2s // Start animation after 0.5 seconds
    &.logo3
        animation-delay: 1s
.logo:hover
    transform: scale(1.1) // Increase size by 5% on hover
    animation: shine 5s infinite
    
@keyframes twinkle
  0%
    opacity: 0
    transform: scale(0.7)
  50%
    opacity: 1
    transform: scale(1)
  100%
    opacity: 0
    transform: scale(0.7)
.Tech-top-text-section
    color: $primaryColor
    font-size: 60px
    font-weight: 700
    padding-top: 20px

.Tech-sec-text-section
    color: $primaryColor
    font-size: 28px
    font-weight: 500
    margin: 1% 15% 5% 15%
.main-flex
    background-color: $backgroundColor
    margin: 5% 15% 5% 15%  
    padding-top: 3%  
    padding-bottom: 2% 
    border-radius: 5px
.sub-flex
    display: flex
    justify-content: space-between 
    margin:0% 5% 0 5%   
.sub-flex2
    display: flex
    justify-content: space-between 
    margin-top:2% 
    margin-right:10%
    margin-left: 15%
    margin-bottom:2%
.sub-flex3
    display: flex
    justify-content: space-between 
    margin:0% 18% 2% 23%                   

.view-btn
   align-items: center
   margin-top: 50px
   padding-bottom: 20px
.view-btn p a
    text-decoration: none
    color: #fff
    padding: 15px 20px 
    font-size: 16px
    font-weight: 600
    border-radius: 35px
    background-color: #1F1454  

.view-btn p :hover
    background-color: #1F1454    
    padding: 15px 20px
    color: #fff
    border-radius: 35px
    font-weight: 600
    border-width: 2px
.tech-mop-flex
    display: none       
//Mopile Responsive 
@media only screen and (min-width: 300px) and (max-width: 600px)
    .Tech-top-text-section
        font-size: 40px
        font-weight: 700
        margin-bottom: 10px  
        margin-right: 3%
        margin-left: 3%  
    
    .Tech-sec-text-section   
        font-size: 25px
        font-weight: 500 
        margin-right:20px
        margin-left:20px
    .main-flex 
        margin: 50px 20px 50px 20px  

    .sub-flex,.sub-flex2,.sub-flex3
        display: none
    .tech-mop-flex
        display: block  
    .mop-sub-flex1
        display: flex
        justify-content: space-between 
        margin:20px 4% 0 4%   
    .mop-sub-flex2
        display: flex
        justify-content: space-between 
        margin:20px 16% 0 16%    
    .mop-sub-flex3
        display: flex
        justify-content: space-between 
        margin:20px 22% 40px 22%   
       
    .view-btn
        padding-bottom: 30px  