.home__hero-section3 {
    background-color:#1B181C;
    padding: 100px 0;
    
  }
  
  .home__hero-row {
    align-items: center;
  }
 
  .row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  
  .col1 {
    
    padding-right: 15px;
    padding-left: 15px;
    max-width: 50%;
    margin-top: 255px;
    
  }
  .round-discription{
    display: flex;
    flex-direction: row;
  
  }
  .top-head-disc{
    color: #fff;
    padding: 15px;
  }
  .top-head{
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 10px;
  }
  .bottom-disc{
    font-size: 24px;

  }
  .home__hero-text-wrapper {
    max-width: 560px;
    padding-top: 0;
    padding-bottom: 20px;
  }
  .content-disc{
    color: #fff;
    font-size: 16px;
    max-width: 460px;
    line-height: 28px;
    padding-top: 10px;
  }
  .end-disc{
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 42px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }
  
  .top-line2 {
    color: #fff;
    font-size: 24px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
  }
  .Navbar-icon{
    height: 20px;
    width: 20px;
    position: absolute;
    margin-left: 20px;
  }
  .heading {
    margin-bottom: 24px;
    font-size: 54px;
    line-height: 72px;
    font-weight: 600;
    color: #fff;
    width: 100%;
  
  }
  .targetimg{
    color: yellow;
    padding-left: 20px;
    size: 40px;
  }
  
  .dark {
    color: #fff;
  }
  
  .darkBg {
    background-color: #1B181C;
  }
  
  .home__hero-subtitle2 {
    width: 100%;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    line-height: 28px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-left: 20px;
  }
  
  .home__hero-img-wrapper {
    max-width: 555px;
  }
  
  
  .section5images1{
      display: flex;
      flex-direction: column;
      margin-top: -80px;
      
  }
  .imagepack{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 80px;
      transition: .3s ease-in-out;
  }
  .imagepack:hover{
    transform: scale(1.1);
    transition: .3s ease-in-out;
  }
  .image-discription{
      color: #fff;
      font-size: 18px;
  }
  .section5-top{
   display: flex;
   padding-bottom: 60px;
  }
  .img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 991px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
   
  }
  
  @media screen and (max-width: 768px) {
    .heading {
      margin-bottom: 24px;
      font-size: 40px;
      line-height: 72px;
      font-weight: 600;
      color: #fff;
      width: 100%;
    
    }
    
    .home__hero-text-wrapper {
      padding-bottom: 65px;
    }
    .col1{
      margin-top: 40px;
      max-width: 100%;
    }
    .col {
      max-width: 100%;
      flex-basis: 100%;

    }
    .section5images1{
      display: none;
      // flex-direction: column;
      // margin-top: -80px;
      
  }
  //   .imagepack{
  //     display: none;
  //     // flex-direction: column;
  //     // align-items: center;
  //     // //padding-right: 20px;
  //     // transition: .3s ease-in-out;
  // }
  }
  