*
    margin: 0
    padding: 0

$primaryColor: #091133  
$SolidBlack:#000
$backgroundColor:#F8F9FF 
$greygroundColor:#E7ECFF
$btnColor:#1f1454 
$btn-color:#101344  
.social-icon-link1
    color: #fff
    font-size: 25px
    padding: 10px
    background: #1B181C
    border-radius: 50px
    margin-right: 15px
    display: flex
    justify-content: center
.Contact-Base 
    margin:3% 5% 50px 5%
.Contact-su
    background-color: $backgroundColor       
    padding: 50px 50px
.Contact-sub
    background-color:$greygroundColor 
    border-radius: 10px
    padding-bottom: 4%
       
.Contact-flex
    display: flex
    justify-content: space-between
    margin-top: 3%
    
.contact-left-container
    margin-left: 5% 
    font-size: 20px    
.phonenumber
    display: flex
    max-width: 200px 
    padding-bottom: 30px
    padding-top: 20px
    margin-bottom: 20px 
.gmailfix 
    display: flex
    min-width: 190px            
.contact-right-container
   margin-right: 30%        
.contact-subtext-top
    font-size: 60px
    font-style: normal
    font-weight: 600 
    letter-spacing: -1.2px
    margin-left: 5%
    padding-top:50px
    margin-bottom: 10px
.contact-subtext-a ,.contact-subtext-b
    font-size: 28px
    font-style: normal
    font-weight: 350
    line-height: 150%
    margin-left: 5%
.input-control
    padding: 20px 25px
    outline: none
    align-items: center
    gap: 12px
    align-self: stretch
    min-width: 400px
    border-radius: 20px  
    border:#d9d9d9 3px solid 
    margin-bottom: 15px   
.contact-logo
    margin-top: 50px
.contact-us
    font-size: 24px
    font-weight: 500
            
.contact-logo-flex
    margin-top: 20px
    display: flex

.c-padding
   background-color: #cad1e6
   padding:8px 9px 2px 1px    
   border-radius: 20px 
   font-size: 23px 
   margin-left: 10px
   align-items: center
   margin-bottom: 10px 
.c-padding a
    color: #000   
.contact-submit-btn
    background-color: $btnColor
    padding:17px 50px
    color: #fff
    border:#d9d9d9 3px solid 
    font-size: 16px
    font-weight: 550
    border-radius: 20px 
   
@media only screen and (min-width: 300px) and (max-width: 600px)
    .Contact-flex
        flex-direction: column    
 
    
    .contact-subtext-top
        font-size: 40px
    .contact-subtext-a,.contact-subtext-b
        font-size: 18px
        margin:10px 5px 1px 20px
        font-weight:600 
    .phonenumber,.gmailfix
        font-size: 20px
    .contact-logo
        padding-top: -10px
    .Contact-flex
        padding-top: 10px 
       
    .input-control  
        min-width: 300px  
        max-width: 380px
        padding: 15px 20px
        
        border-radius: 10px
    .contact-right-container
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        padding-top: 10px
        text-align: center   
        
    .contact-submit-btn
       align-items: center
       padding:12px 40px 
    .Contact-su   
       padding: 0   
    .btn-submit
        text-align: center
        border-radius: 2px  
    .textarea
        border-radius: 5px       
@media only screen and (min-width: 700px) and (max-width: 1024px)
    .Contact-flex
        flex-direction: column
    .contact-right-container
        margin: 0 20px 0 20px
        padding-top: 10px
        text-align: center   
    .Contact-su   
       padding: 0   
   