.footer-container {
  background: #1B181C;
  color: #fff;
  padding: 4rem 0 2rem 0;
   width: 100%;
    display: flex;
    justify-content: space-between;
   flex-direction: column;
/* $backgroundColor:#FCF804 */

}
.head{
  color: #FFDD1F;
}
.home__hero-imgs{
 padding-right: 10px;
 padding-bottom: 3px;
}
.footer-subscription{
  display: flex;
  flex-direction: row;
  padding-left:150px;
}
.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 19px;
  line-height: 32px;
  padding-top: 32px;
  width: 285px;
  margin-left: -20px;
  font-family:sans-serif;
  /* font-weight: 3px; */
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}
.navbar-logo-b{
  font-size: 32px;
  text-decoration: none;
  color:#fff ;
  font-family: 700;
  
}
.log-b{
  color: #FFDD1F;
  font-weight: bold;
  text-transform: capitalize;
}


.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 260px;
  box-sizing: border-box;
 line-height: 42px;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}


.footer-link-items a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  margin-bottom: 0.5rem;
}



.footer-email-form h2 {
  margin-bottom: 2rem;
}
.web-register{
  display: flex;
  justify-content: center;
  padding-top:50px;
  font-size: 18px;
line-height: 20px;
color: #6a6363;
font-weight: 500;
}


/* Social Icons */
.social-icon-link {
  color: #FFDD1F;
  font-size: 30px;
  padding:5px;
  background: #1B181C;
  margin-right: 15px;
  display: flex;
  justify-content: center;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
 
  
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
  
 
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }
  .footer-subscription{
    display: flex;
    padding-left: 60px;
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px){
  .web-register{
    display: flex;
    justify-content: center;
    padding-top:50px;
    font-size: 14px;
    line-height: 20px;
    color: #6a6363;
    font-weight: 500;
  }
  .footer-links{
    display: flex;
    flex-direction: column;
  }
  .footer-subscription-heading{
     font-weight: 520;
     width: 100%;
  }
  .footer-link-items{
    width: 50%;
  }
}