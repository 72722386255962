.home__hero-section4 {
    background-color:#FFDD1F;
    padding: 160px 0;
  }
  
  .home__hero-row {
    align-items: center;
  }
 .btnwide{
   display: flex;
   justify-content: center;
 }
  .row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  .dark1{
      color: #1B181C;
  }
  .discription-cric{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }
  .divbuttons{
    display: flex;
    padding-top: 30px;
  }
  .image1{
    margin-top: 180px;
  }
 .image2{
   margin-top: 200px;
 }
 .Navbar-icon1{
  height: 20px;
    width: 20px;
    position: absolute;
    margin-left: 20px;
 }
 
 
  .cric{
      font-size: 26px;
      padding-top: 30px;
      color: #1B181C;
      font-weight: 600;
  }
  .discription-cric{
    padding-top: 15px;
  }
  .image{
    padding-top: 30px;
      padding-bottom: 10px;
  }
  .col1 {
    
    padding-right: 15px;
    padding-left: 15px;
    max-width: 50%;
    margin-top: 255px;
    
  }
  .round-discription{
    display: flex;
    flex-direction: row;
  
  }
  .top-head-disc{
    color: #fff;
    padding: 15px;
  }
  .top-head{
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 10px;
  }
  .bottom-disc{
    font-size: 24px;

  }
  .home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
  }
  .content-disc{
    color: #fff;
    font-size: 16px;
    max-width: 460px;
    line-height: 28px;
    padding-top: 10px;
  }
 
  .top-line {
    color: #1B181C;
    font-size: 24px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .Navbar-icon{
    height: 20px;
    width: 20px;
    position: absolute;
    margin-left: 20px;
  }
  .heading1 {
    margin-bottom: 24px;
    font-size: 46px;
    line-height: 72px;
    font-weight: 600;
    color: #1B181C;
    width: 100%;
  }
  
  .home__hero-subtitle2 {
    width: 100%;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    line-height: 28px;
  }
  
  .home__hero-img-wrapper {
    max-width: 555px;
  }
  
  
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 991px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
   
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding-bottom: 65px;
    }
    .col1{
      margin-top: 40px;
      max-width: 100%;
    }
    .image2{
      margin-top: 50px;
    }
    .col {
      max-width: 100%;
      flex-basis: 100%;

    }
  }
  