.hero-container{
 display: flex;
 flex-direction: column;
 padding: 20px;
 align-items: center;
 justify-self: center;

}
.title{
  font-size: 40px;

}

.sub-title{
  font-size: 20px;
  font-family: sans-serif;
}
.maindiv{
  display: flex;
  flex-direction: column;
}
.wrapper {
  width: 90%;
  margin-inline: auto;
  position: relative;
  height: 150px;
  overflow: hidden;
  margin-top: 5rem;
  -webkit-mask-image: linear-gradient(
    to right, 
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(
    to right, 
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0),
    );
}
.wrapper2 {
  
  width: 90%;
  margin-inline: auto;
  position: relative;
  height: 150px;
  overflow: hidden;
  margin-top: 2rem;
  -webkit-mask-image: linear-gradient(
    to right, 
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(
    to right, 
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0),
    );
}

@keyframes scrollRight{
  to {
    right: -200px;
  }
}












@keyframes scrollLeft {
  to {
    left: -200px;
  }
}

.itemm{
  height:150px;
  width:200px;
  position: absolute;
  right:max(calc(200px * 8), 100%) ;
  animation-name: scrollRight;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.itemm:hover{
  scale: 1.1;
  box-shadow: inset;
}
.item:hover{
  scale: 1.1;
}
.item {
  height:100px;
  width:200px;
  position: absolute;
  left:max(calc(200px * 8), 100%) ;
  animation-name: scrollLeft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
 }




.item1 {
  height: 100px;
  width: 200px;
  margin-top:20px;
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}
.fbcakes{
  height: 100px;
  width: 200px;

  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.item2 {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.item3 {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.item4 {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.item5 {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.item6 {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.item7 {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.item8 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}

/* .item9 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}
.item10 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
} */
